import { EMPTY } from "../../constants/common";
import { Country } from "./price";
import { TransactionModel } from "./transactions";

export enum PaymentMethodType {
  CreditCard = "CreditCard",
  PayPal = "PayPal",
  GiftCertificate = "GiftCertificate",
}

export enum CreditCardType {
  AMERICAN_EXPRESS = "American Express",
  DANKORT = "Dankort",
  DINERS_CLUB = "Diners Club",
  DISCOVER = "Discover",
  ELO = "ELO",
  FORBRUGSFORENINGEN = "Forbrugsforeningen",
  HIPERCARD = "Hipercard",
  JCB = "JCB",
  LASER = "Laser",
  MAESTRO = "Maestro",
  MASTER_CARD = "MasterCard",
  TEST_CARD = "Test Card",
  UNION_PAY = "Union Pay",
  UNKNOWN = "Unknown",
  VISA = "Visa",
  TARJETA_NARANJA = "Tarjeta Naranja",
  SWITCH = "Switch",
  DINA_CARD = "DinaCard",
}

export interface CreditCard {
  account: string;
  expirationDate: string;
  type?: CreditCardType;
}

export interface BillingAddress {
  postalCode: string;
  country: string;
  district?: string;
}

export interface PaymentMethod {
  id: string;
  created: string;
  account: string;
  type: PaymentMethodType;
  billingAddress?: BillingAddress;
  expirationDate?: string;
  cardType?: CreditCardType;
  default?: boolean;
  vid?: string;
  metadata?: { show?: boolean };
}

export const formatAccount = (account: string): string => "**** **** **** " + account.substring(account.length - 4);

export const getPaymentMethodText = (transaction: TransactionModel) => {
  if (!transaction.paymentInformation) {
    const isAllPaidByGiftCardBalance =
      Number(transaction.price.amount) === 0 && Number(transaction.price.accountAmount) > 0;
    return isAllPaidByGiftCardBalance ? "Gift Card Balance" : EMPTY;
  }

  const { type, cardType, account } = transaction.paymentInformation;
  const cardTypeText = cardType ? `Credit Card ${cardType} ${formatAccount(account)}` : "";
  const paypalTypeText = account ? `Paypal ${account}` : "Paypal";

  if (!type) {
    return cardType ? cardTypeText : "";
  }

  if (type === PaymentMethodType.CreditCard) {
    return cardTypeText;
  }
  if (type === PaymentMethodType.PayPal) {
    return paypalTypeText;
  }
  if (type === PaymentMethodType.GiftCertificate) {
    return "Gift Card Balance";
  }

  return EMPTY;
};

export function getPaymentMethodTextByPaymentMethod(paymentMethod: PaymentMethod) {
  if (!paymentMethod.id) return EMPTY;
  const { type: paymentMethodType } = paymentMethod || {};
  let paymentMethodText = "";
  switch (paymentMethodType) {
    case PaymentMethodType.CreditCard:
      if (!paymentMethod?.cardType) {
        return "";
      }

      const { account, cardType } = paymentMethod;
      paymentMethodText = `Credit Card ${cardType} ${formatAccount(account)}`;
      break;
    case PaymentMethodType.PayPal:
      const paypalEmail = paymentMethod.account;
      paymentMethodText = `PayPal ${paypalEmail}`;
      break;
  }
  return paymentMethodText;
}

export const getCountryName = (billingAddressCountry: string, countries?: Country[]): string =>
  countries?.find((country) => country.iso2.toUpperCase() === billingAddressCountry?.toUpperCase())?.displayName ??
  billingAddressCountry;
