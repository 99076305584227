import { CreditCardType } from "./paymentMethod";

export enum SubscriptionSource {
  eCom = "eCom",
  Naviga = "Naviga",
  RECURLY = "recurly",
}

export enum SubscriptionResponseStatus {
  ACTIVE = "active",
  CANCELLED = "cancelled",
  PENDING_CANCEL = "pending_cancel",
  PENDING_PAUSE = "pending_pause",
  PAUSED = "paused",
  PENDING_ACTIVATION = "pending_activation",
  Expired = "expired",
}

export interface SubscriptionResponse {
  title: string;
  source: SubscriptionSource;
  status: SubscriptionResponseStatus;
  payment_method?: {
    card_type?: CreditCardType;
    account: string;
    billing_address: {
      country: string;
    };
  };
  subscription_id: string;
  start_date: string;
  end_date: string;
  free_trail_end_date?: string;
  grace_period_end_date?: string;
  paused_start_date?: string;
  pid: string;
  next_billing?: {
    date?: string;
    amount: number;
    currency_symbol: string;
  };
}

export enum SubscriptionStatus {
  ACTIVE = "Active",
  PENDING_CANCEL = "Pending Cancel",
  CANCELLED = "Canceled",
  PENDING_PAUSE = "Pending Pause",
  PAUSED = "Paused",
  PENDING_ACTIVATION = "Pending Activation",
  FREE_TRIAL = "Free Trial",
  GRACE_PERIOD = "Grace Period",
  Expired = "Expired",
  UNKNOWN = "Unknown",
}

export interface SubscriptionModel {
  title: string;
  source: SubscriptionSource;
  status: SubscriptionStatus;
  paymentMethod?: {
    cardType?: CreditCardType;
    account: string;
    billingAddress: {
      country: string;
    };
  };
  subscriptionId: string;
  startDate: string;
  endDate?: string;
  pauseStartDate?: string;
  pid: string;
  nextBilling?: {
    date?: string;
    amount: number;
    currencySymbol: string;
  };
  actions: string[];
}

export interface SubscriptionUpsellDowngradeListResponse {
  pid: string;
  plan_title: string;
  base_price: {
    id: string;
    name: string;
  };
  billing_plan?: {
    id: string;
    name: string;
  };
}

export interface SubscriptionUpsellDowngradeListItem {
  pid: string;
  planTitle: string;
  basePrice: {
    id: string;
    name: string;
  };
  billingPlan?: {
    id: string;
    name: string;
  };
}
