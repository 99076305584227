import { ContentBase } from "./description";
import { LocaleType } from "../../utils/locale";

export enum PromotionStatus {
  NOT_STARTED = "not_started",
  REDEEMABLE = "redeemable",
  EXPIRED = "expired",
  MAXED_OUT = "maxed_out",
}

export enum PromotionDiscountType {
  PERCENTAGE_OFF = "percentage_off",
  AMOUNT_OFF = "amount_off",
  BUY_ONE_GIFT_ONE = "buy_one_gift_one",
}

export interface IdStringNameStringType {
  id: string;
  name: string;
}

export interface PromotionModel {
  id: string;
  name: string;
  code: string;
  startAt: string;
  endAt?: string;
  discountType: PromotionDiscountType;
  percentageDiscount?: string;
  currencyCode?: string;
  amountDiscount?: string;
  status: PromotionStatus;
  basePrices?: IdStringNameStringType[];
  promotionCodeTexts?: {
    [LocaleType.English]: string;
    [LocaleType.Spanish]: string;
  };
}

export interface PromotionListModel {
  list: PromotionModel[];
  total: number;
}

export interface Currency {
  currency: string;
  amount: number;
}

export interface RecurlyCouponModel {
  id: string;
  name: string;
  code: string;
  startedAt: string;
  endedAt: string | null;
  status: PromotionStatus;
  discountType: PromotionDiscountType;
  percent?: number;
  currencies?: Currency[];
}

export interface DescriptionDisclosure {
  billingPlanId?: string;
  billingPlanName?: string;
  description?: ContentBase[];
  disclosure?: ContentBase[];
}

export interface PromotionWithRecurlyFormData {
  code: string;
  displayPromotionCode: string;
  recurlyCouponId: string;
  basePriceIds: string[];
  countryIds?: number[];
  codeTextEn?: string;
  codeTextEs?: string;
  descriptionDisclosures?: DescriptionDisclosure[];
}

export interface PromotionWithBuyOneGiftOneFormData {
  code: string;
  name: string;
  timeRange: [string, string];
  basePlanId: string;
  giftPlanId: string;
  codeTextEn?: string;
  codeTextEs?: string;
  description?: ContentBase[];
  disclosure?: ContentBase[];
}

export interface PromotionBaseDetailsModel {
  id: string;
  name: string;
  code: string;
  startedAt: string;
  status: PromotionStatus;
  discountType: PromotionDiscountType;
  canBeDeleted: boolean;
  codeText?: {
    [LocaleType.English]: string;
    [LocaleType.Spanish]: string;
  };
  descriptionDisclosures?: DescriptionDisclosure[];
}

export interface PromotionWithBuyOneGiftOneDetailsModal extends PromotionBaseDetailsModel {
  endedAt: string;
  buyPlan: PlanModal;
  giftPlan: PlanModal;
}

export interface PromotionWithRecurlyDetailsModal extends PromotionBaseDetailsModel {
  recurlyCouponId: string;
  appliedBasePrices: IdStringNameStringType[];
  appliedCountries?: IdStringNameStringType[];
  endedAt?: string;
  percent?: number;
  currencies?: Currency[];
}

export interface PlanModal {
  id: string;
  pid: string;
  title: string;
  billingPlanId: string;
  billingPlanName: string;
}
