import React from "react";
import styles from "./EComRichTextDescription.module.scss";

interface EComRichTextDescriptionProps {
  showDiscountprice?: boolean;
  showOflessons?: boolean;
}

function EComRichTextDescription({ showDiscountprice, showOflessons }: EComRichTextDescriptionProps) {
  return (
    <div className={styles.hint}>
      <div className={styles.title}>
        <p>Variable description:</p>
      </div>
      <div className={styles.detail}>
        <p>{"{tax}"}</p>
        <p>
          1. If tax exclusive, display text "plus applicable taxes if any"(en) or "más impuestos aplicables si los
          hay"(es)
        </p>
        <p>2. If tax inclusive, display text "tax inclusive"(en) or "impuestos incluidos"(es)</p>
        <p>{"{price}"}</p>
        <p>Display "currency symbol + price number", e.g.: $19.95</p>
        {showDiscountprice && (
          <>
            <p>{"{discountprice}"}</p>
            <p>Display "currency symbol + price number", e.g.: $19.95</p>
          </>
        )}
        {showOflessons && (
          <>
            <p>{"{#oflessons}"}</p>
            <p>Display lessons count for lifetime plan, e.g.: 30</p>{" "}
          </>
        )}
      </div>
    </div>
  );
}

export default EComRichTextDescription;
