import {
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { capitalize } from "lodash";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { SelectValue } from "antd/es/select";
import { BasePriceModel, PriceStatus } from "../../../../types/model/price";
import * as basePriceAPI from "../../../../services/basePrice";
import {
  DATETIME_FORMAT_WITH_MINUTE,
  DEFAULT_MAX_PAGINATION,
  nonZeroHundredthDecimalValidator,
  validateMessages,
} from "../../../../constants/common";
import styles from "./PromotionForm.module.scss";
import { preventNonNumericalInput } from "../../../../utils/preventNonNumericalInput";
import { DropDown } from "../../../../assets/icons";
import { CodeUsageType, DescriptionDisclosure, DiscountType } from "../../../../types/model/promotion";
import DebounceSearchInput from "../../../../components/DebounceSearchInput";
import * as eComProductAPI from "../../../../services/eComProduct";
import { EComProductListRequest } from "../../../../types/dto/request/eComProduct";
import { EComProductModel } from "../../../../types/model/eComProduct";
import {
  DescriptionAndDisclosure,
  DescriptionAndDisclosureContent,
} from "../../RecurlyPromotion/components/DescriptionAndDisclosure";
import { PromotionCode } from "./PromotionCode";
import { PromotionQuantity } from "./PromotionQuantity";

interface Props {
  form: FormInstance;
  descriptionDisclosures: DescriptionDisclosure[];
  updateDescriptionDisclosures: (descriptionDisclosures: DescriptionDisclosure[]) => void;
}

interface Option {
  label: string;
  value: string | number;
}

export function PromotionForm({ form, descriptionDisclosures, updateDescriptionDisclosures }: Props) {
  const [basePriceList, setBasePriceList] = useState<BasePriceModel[]>([]);
  const [currencyList, setCurrencyList] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { RangePicker } = DatePicker;
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [planOptionList, setPlanOptionList] = useState<Option[]>([]);
  const [isSelectSubscription, setIsSelectSubscription] = useState<boolean>(
    form.getFieldValue("isSubscription") || true
  );
  const [selectDiscountType, setSelectDiscountType] = useState<DiscountType>(
    form.getFieldValue("discountType") || DiscountType.PERCENTAGE
  );
  const [gitPlanList, setGiftPlanList] = useState<EComProductModel[]>([]);
  const [codeUsageType, setCodeUsageType] = useState(CodeUsageType.REGULAR);

  useEffect(() => {
    const formIsSubscription = form.getFieldValue("isSubscription");
    if (formIsSubscription !== isSelectSubscription) {
      handleProductTypeChange();
    }
  }, [form.getFieldValue("isSubscription")]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      basePriceAPI.fetchCurrencyAssociation(),
      basePriceAPI.fetchAllCountries(),
      basePriceAPI.fetchBasePriceList({
        status: PriceStatus.ACTIVE,
        page: 1,
        limit: DEFAULT_MAX_PAGINATION.size,
      }),
      eComProductAPI.fetchProductList({
        limit: DEFAULT_MAX_PAGINATION.size,
        type: "GiftCode",
      } as EComProductListRequest),
    ])
      .then(([associations, countries, basePriceList, giftPlanList]) => {
        setBasePriceList(basePriceList.list);
        setCountryList(countries.map((item) => ({ label: item.displayName, value: item.id })));
        setCurrencyList(
          associations.map((item) => ({
            label: item.code.toUpperCase(),
            value: item.code.toUpperCase(),
          }))
        );
        setGiftPlanList(giftPlanList.list);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectDiscountType === DiscountType.BUY_ONE_GIFT_ONE && descriptionDisclosures.length > 0) {
      const { description = [], disclosure = [] } = descriptionDisclosures[0];
      if (description) {
        description.forEach((item) => {
          form.setFieldsValue({
            [`${item.locale}DescriptionContent`]: item.content,
          });
        });
      }
      if (disclosure) {
        disclosure.forEach((item) => {
          form.setFieldsValue({
            [`${item.locale}DisclosureContent`]: item.content,
          });
        });
      }
    }

    handleVariantsSearch(form.getFieldValue("basePlanPid")).then();
  }, [descriptionDisclosures, form]);

  const handleProductTypeChange = () => {
    const { isSubscription } = form.getFieldsValue();
    setIsSelectSubscription(isSubscription);
    if (!isSubscription) {
      form.resetFields(["isRestrictToNew", "billingCycle", "descriptionContent", "disclosureContent"]);
    } else form.setFieldsValue({ isStackable: false });
  };

  const validateDiscount = async (value: string | undefined, fieldName: string): Promise<void> => {
    if (!value) {
      return Promise.reject(`'${fieldName}' is required`);
    }

    if (!nonZeroHundredthDecimalValidator.test(value)) {
      return Promise.reject(`'${fieldName}' must be a positive number with up to two decimal places`);
    }
    const numberValue = parseFloat(value);
    if (isNaN(numberValue) || numberValue < 0.01 || numberValue > 100) {
      return Promise.reject(`'${fieldName}' must be between 0.01 and 100`);
    }
    return Promise.resolve();
  };

  const validateQuantity = async (value: string | undefined, fieldName: string): Promise<void> => {
    if (!value) {
      return Promise.reject(`'${fieldName}' is required`);
    }

    if (Number.parseInt(value) <= 0) {
      return Promise.reject(`'${fieldName}' must be a positive number`);
    }
    return Promise.resolve();
  };

  const validateEndBillingCycle = useCallback(() => {
    const { billingCycle } = form.getFieldsValue();
    const value = parseInt(billingCycle, 10);
    if (Number.isNaN(value) || (!value && value !== 0)) {
      return Promise.reject("'Applied Billing cycle' is required");
    } else if (Number(value) < 1) {
      return Promise.reject("'Applied Billing cycle' must be greater than 0");
    } else {
      return Promise.resolve();
    }
  }, [form]);

  const handlePromotionTypeChange = useCallback(() => {
    const { discountType, isSubscription } = form.getFieldsValue();

    if (isSubscription === undefined) {
      form.setFieldsValue({ isSubscription: true });
    }

    setSelectDiscountType(discountType);
  }, [form]);

  const handlePromotionCodeUsageChange = useCallback(() => {
    const { codeUsage } = form.getFieldsValue();
    form.resetFields(["code", "quantity"]);
    setCodeUsageType(codeUsage);
  }, [form]);

  const handleVariantsSearch = async (search: string): Promise<Option[]> => {
    const res = await eComProductAPI.fetchProductVariantsByFuzzySearch(search);
    const options = res
      ? res.map(({ id, title, pid, billingPlan }) => ({
          key: id,
          label: billingPlan ? `${pid} ${title} / ${billingPlan.name}` : `${pid} ${title}`,
          value: id,
        }))
      : [];
    setPlanOptionList(options);
    return options;
  };

  const handleBasePlanInputChange = (value: SelectValue) => {
    form.setFieldsValue({ basePlanId: value });
  };

  const PromotionCodeTextTooltip = {
    title: (
      <div>
        If both English and Spanish are not populated, then a default value will be displayed. If Spanish text is not
        configured, then display English.
      </div>
    ),
    icon: <InfoCircleOutlined />,
  };

  const PromotionCodeUsageTooltip = {
    title: <div>Regular: no limits on redemptions; Single Use: one code valid for one redemption.</div>,
    icon: <InfoCircleOutlined />,
  };

  return (
    <Spin spinning={isLoading} size="large">
      <ConfigProvider form={{ validateMessages }}>
        <Form form={form} validateTrigger={"onBlur"} layout="vertical" className={styles.promotionForm}>
          <header className={styles.subtitle}>Basic info</header>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label="Promotion name" name="name" rules={[{ type: "string", max: 100, required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                label="Time range"
                name="timeRange"
                rules={[{ required: true }]}
                validateTrigger={["onChange"]}
              >
                <RangePicker
                  style={{ width: "100%" }}
                  showTime={{ format: "HH:mm" }}
                  format={DATETIME_FORMAT_WITH_MINUTE}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={6}>
              <Form.Item
                label="Promotion code usage"
                name="codeUsage"
                rules={[{ required: true }]}
                initialValue={CodeUsageType.REGULAR}
                tooltip={PromotionCodeUsageTooltip}
              >
                <Radio.Group onChange={handlePromotionCodeUsageChange}>
                  {Object.values(CodeUsageType).map((type) => (
                    <Radio value={type} key={type}>
                      {capitalize(type)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            {codeUsageType === CodeUsageType.REGULAR && (
              <Col span={6}>
                <Form.Item label="Promotion code" name="code" rules={[{ type: "string", max: 25, required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            )}
            {codeUsageType === CodeUsageType.SINGLE_USE && (
              <Col span={6}>
                <Form.Item
                  label="Promotion code"
                  name="code"
                  rules={[{ required: true, message: "Promo code prefix is required." }]}
                >
                  <PromotionCode />
                </Form.Item>
              </Col>
            )}
            {codeUsageType === CodeUsageType.SINGLE_USE && (
              <Col span={6}>
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  rules={[{ required: true, validator: async (_, value) => validateQuantity(value, "Quantity") }]}
                >
                  <PromotionQuantity />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={32}>
            <Col span={6}>
              <Form.Item
                label="Promotion code text(English)"
                name="codeTextEn"
                rules={[{ type: "string", max: 100 }]}
                tooltip={PromotionCodeTextTooltip}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Promotion code text(Spanish)"
                name="codeTextEs"
                rules={[{ type: "string", max: 100 }]}
                tooltip={PromotionCodeTextTooltip}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider className={styles.divider} />

          <header className={styles.subtitle}>Promotion details</header>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                label="Promotion type"
                name="discountType"
                rules={[{ required: true }]}
                initialValue={DiscountType.PERCENTAGE}
              >
                <Radio.Group onChange={handlePromotionTypeChange}>
                  {Object.values(DiscountType).map((type) => (
                    <Radio value={type} key={type}>
                      {capitalize(type)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Form.Item noStyle shouldUpdate>
              {() => {
                const discountType = form.getFieldValue("discountType");
                return (
                  <>
                    {discountType === DiscountType.AMOUNT && (
                      <>
                        <Col span={6}>
                          <Form.Item label="Currency" name="currencyCode" rules={[{ required: true }]}>
                            <Select
                              allowClear
                              optionFilterProp="label"
                              getPopupContainer={(trigger) => trigger.parentNode}
                              showArrow
                              showSearch
                              suffixIcon={<DropDown />}
                              placeholder="Please select a currency"
                              options={currencyList}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label="Amount"
                            name="amountDiscount"
                            rules={[
                              { required: true },
                              {
                                pattern: nonZeroHundredthDecimalValidator,
                                message: "'Amount' must be a positive number with up to two decimal places",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {discountType === DiscountType.PERCENTAGE && (
                      <>
                        <Col span={6}>
                          <Form.Item
                            label="Discount"
                            name="percentageDiscount"
                            rules={[
                              {
                                required: true,
                                validator: async (_, value) => validateDiscount(value, "Discount"),
                              },
                            ]}
                          >
                            <Input addonAfter="%" />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
          <Divider className={styles.divider} />
          <Form.Item noStyle shouldUpdate>
            {() =>
              form.getFieldValue("discountType") === DiscountType.BUY_ONE_GIFT_ONE ? (
                <>
                  <Col span={18}>
                    <Form.Item label="Base plan" name="basePlanId" rules={[{ required: true, type: "string" }]}>
                      <DebounceSearchInput
                        showSearch
                        allowClear
                        onChange={handleBasePlanInputChange}
                        fetchOptions={handleVariantsSearch}
                        existedOptions={planOptionList}
                        onClear={() => {
                          setPlanOptionList([]);
                        }}
                        placeholder="Search PID or plan name"
                        suffixIcon={<SearchOutlined className={styles.searchIcon} />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Gift plan" name="giftPlanId" rules={[{ required: true, type: "string" }]}>
                      <Select
                        allowClear
                        optionFilterProp="label"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showArrow
                        suffixIcon={<DropDown />}
                        placeholder="Please select applied gift plan"
                        options={gitPlanList.map(({ id, planName }) => ({
                          label: planName,
                          value: id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Row gutter={32}>
                    <Col span={6}>
                      <Form.Item
                        label="Is subscription/all access"
                        name="isSubscription"
                        initialValue={true}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group onChange={handleProductTypeChange}>
                          <Space size={8}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate>
                      {() =>
                        form.getFieldValue("isSubscription") === true && (
                          <>
                            <Col span={6}>
                              <Form.Item
                                label="Restrict to new subscription"
                                name="isRestrictToNew"
                                initialValue={true}
                                rules={[{ required: true }]}
                              >
                                <Radio.Group>
                                  <Space size={8}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                  </Space>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Applied billing cycle"
                                rules={[
                                  {
                                    required: true,
                                    validator: validateEndBillingCycle,
                                  },
                                ]}
                                name="billingCycle"
                              >
                                <Space size={22} align="baseline" className={styles.littleInput}>
                                  <Form.Item noStyle>
                                    <Input disabled={true} value={1} />
                                  </Form.Item>
                                  <span>to</span>
                                  <Form.Item noStyle name="billingCycle">
                                    <InputNumber
                                      precision={0}
                                      onKeyPress={preventNonNumericalInput}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Space>
                              </Form.Item>
                            </Col>
                          </>
                        )
                      }
                    </Form.Item>
                  </Row>
                  <Divider className={styles.divider} />
                  <Col span={24}>
                    <Form.Item
                      label="Applied base price"
                      name="basePriceIds"
                      rules={[{ required: true, type: "array" }]}
                    >
                      <Select
                        allowClear
                        optionFilterProp="label"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showArrow
                        suffixIcon={<DropDown />}
                        mode="multiple"
                        placeholder="Please select applied base price"
                        options={basePriceList.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="countryIds" label="Applied country/region">
                      <Select
                        allowClear
                        optionFilterProp="label"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showArrow
                        suffixIcon={<DropDown />}
                        mode="multiple"
                        placeholder="Please select applied country/region"
                        options={countryList}
                      />
                    </Form.Item>
                  </Col>
                </>
              )
            }
          </Form.Item>
          <Divider className={styles.divider} />

          <Form.Item noStyle shouldUpdate>
            {selectDiscountType === DiscountType.BUY_ONE_GIFT_ONE && (
              <>
                <header className={styles.subtitle}>Description & Disclosure</header>
                <DescriptionAndDisclosureContent editable={true} />
              </>
            )}
          </Form.Item>
        </Form>
      </ConfigProvider>

      {selectDiscountType !== DiscountType.BUY_ONE_GIFT_ONE && isSelectSubscription && (
        <DescriptionAndDisclosure
          editable={true}
          descriptionDisclosures={descriptionDisclosures}
          updateDescriptionDisclosures={updateDescriptionDisclosures}
        />
      )}
    </Spin>
  );
}
