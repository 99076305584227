import React, { useEffect, useState } from "react";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { Dropdown, Menu, Table } from "antd";
import { SubscriptionModel, SubscriptionSource } from "../../../types/model/subscriptions";
import useResizableTableHeader from "../../../hooks/useResizableTableHeader";
import { fetchSubscriptions } from "../../../services/subscription";
import ResizableTitle from "../../../components/ResizableTitle";
import { getTotalDisplay } from "../../../utils/getTotalDisplay";
import CancelSubscriptionModal from "./components/CancelSubscriptionModal";
import { DATETIME_FORMAT, EMPTY } from "../../../constants/common";
import { More } from "../../../assets/icons";
import PauseSubscriptionModal from "./components/PauseSubscriptionModal";
import RestartSubscriptionModal from "./components/RestartSubscriptionModal";
import useAppAuth0 from "../../../hooks/useAppAuth0";
import UpgradeSubscriptionModal from "./components/UpgradeSubscriponModal";
import DowngradeSubscriptionModal from "./components/DowngradeSubscriptionModal";
import { CustomerInfoResponse, CustomerResponse } from "../../../types/dto/response/customer";
import ApplyPromoCodeModal from "./components/ApplyPromoCodeModal";
import ResumeSubscriptionModal from "./components/ResumeSubscriptionModal";
import ExtendBillingDateModal from "./components/ExtendBillingDateModal";
import SwitchSubscriptionModal from "./components/SwitchSubscriptionModal";
import RevokeEntitlementModal from "./components/RevokeEntitlementModal";

export default function Subscriptions({ customer }: { customer: CustomerResponse & CustomerInfoResponse }) {
  const { user } = useAppAuth0();
  const { auth0Id, email } = customer;
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<SubscriptionModel[]>([]);
  const [cancellingSubscription, setCancellingSubscription] = useState<SubscriptionModel>({} as SubscriptionModel);
  const [revokingSubscription, setRevokingSubscription] = useState({} as SubscriptionModel);
  const [pausingSubscription, setPausingSubscription] = useState<SubscriptionModel>({} as SubscriptionModel);
  const [restartingSubscription, setRestartingSubscription] = useState<SubscriptionModel>({} as SubscriptionModel);
  const [upgradingSubscription, setUpgradingSubscription] = useState<SubscriptionModel>({} as SubscriptionModel);
  const [downgradingSubscription, setDowngradingSubscription] = useState<SubscriptionModel>({} as SubscriptionModel);
  const [applyPromoCodeSubscription, setApplyPromoCodeSubscription] = useState<SubscriptionModel>(
    {} as SubscriptionModel
  );
  const [extendBillingDateSubscription, setExtendBillingDateSubscription] = useState<SubscriptionModel>(
    {} as SubscriptionModel
  );
  const [resumingSubscription, setResumingSubscription] = useState<SubscriptionModel>({} as SubscriptionModel);
  const [switchSubscription, setSwitchSubscription] = useState<SubscriptionModel>({} as SubscriptionModel);

  const columnsToDisplay = useResizableTableHeader<SubscriptionModel>(getColumnConfig());

  useEffect(() => {
    refreshList();
  }, []);

  function refreshList() {
    setIsLoading(true);
    fetchSubscriptions(auth0Id)
      .then((subscriptions) => {
        setSubscriptions(subscriptions);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getColumnConfig(): ColumnType<SubscriptionModel>[] {
    return [
      {
        title: "Subscription ID",
        dataIndex: "subscriptionId",
        width: 160,
        ellipsis: true,
      },
      {
        title: "PID",
        dataIndex: "pid",
        width: 160,
        ellipsis: true,
      },
      {
        title: "Plan name",
        dataIndex: "title",
        width: 220,
        ellipsis: true,
      },
      {
        title: "Source",
        dataIndex: "source",
        width: 100,
        ellipsis: true,
        render: (value: SubscriptionSource) => (value === SubscriptionSource.RECURLY ? "eCom (Recurly)" : value),
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 140,
        ellipsis: true,
      },
      {
        title: "Start date",
        dataIndex: "startDate",
        width: 160,
        ellipsis: true,
        render: (value) => {
          return value ? moment(value).format(DATETIME_FORMAT) : EMPTY;
        },
      },
      {
        title: "Expiration date",
        dataIndex: "endDate",
        width: 160,
        ellipsis: true,
        render: (value) => {
          return value ? moment(value).format(DATETIME_FORMAT) : EMPTY;
        },
      },
      {
        title: "Next billing date",
        width: 160,
        ellipsis: true,
        render: (_, model) => {
          const nextBillingDate = model.nextBilling?.date;
          return nextBillingDate ? moment(nextBillingDate).format(DATETIME_FORMAT) : EMPTY;
        },
      },
      {
        title: "Next billing amount",
        width: 160,
        ellipsis: true,
        render: (_, model) => {
          return model.nextBilling?.amount
            ? `${model.nextBilling.currencySymbol}${model.nextBilling.amount.toFixed(2)}`
            : EMPTY;
        },
      },
      {
        title: "Action",
        width: 80,
        fixed: "right",
        render: (_, model) => {
          return model.actions.length > 0 && user.canUpdateB2CCustomerSubscription ? (
            <Dropdown trigger={["click"]} overlay={getActionButtons(model)}>
              <More />
            </Dropdown>
          ) : (
            EMPTY
          );
        },
      },
    ];
  }

  const getActionButtons = (model: SubscriptionModel) => {
    const { actions } = model;
    return (
      <Menu>
        {actions.includes("applyPromoCode") && (
          <Menu.Item
            key="applyPromoCode"
            onClick={() => {
              setApplyPromoCodeSubscription(model);
            }}
          >
            Apply promo code
          </Menu.Item>
        )}
        {actions.includes("upsell") && (
          <Menu.Item
            key="upsell"
            onClick={() => {
              setUpgradingSubscription(model);
            }}
          >
            Upsell
          </Menu.Item>
        )}
        {actions.includes("downgrade") && (
          <Menu.Item
            key="downgrade"
            onClick={() => {
              setDowngradingSubscription(model);
            }}
          >
            Downgrade
          </Menu.Item>
        )}
        {actions.includes("switchPlan") && (
          <Menu.Item
            key="switchPlan"
            onClick={() => {
              setSwitchSubscription(model);
            }}
          >
            Switch plan
          </Menu.Item>
        )}
        {actions.includes("restart") && (
          <Menu.Item
            key="restart"
            onClick={() => {
              setRestartingSubscription(model);
            }}
          >
            Restart
          </Menu.Item>
        )}
        {actions.includes("extendBillingDate") && (
          <Menu.Item
            key="extendBillingDate"
            onClick={() => {
              setExtendBillingDateSubscription(model);
            }}
          >
            Extend billing date
          </Menu.Item>
        )}
        {actions.includes("pause") && (
          <Menu.Item
            key="pause"
            onClick={() => {
              setPausingSubscription(model);
            }}
          >
            Pause
          </Menu.Item>
        )}
        {actions.includes("resume") && (
          <Menu.Item
            key="resume"
            onClick={() => {
              setResumingSubscription(model);
            }}
          >
            Resume
          </Menu.Item>
        )}
        {actions.includes("cancel") && (
          <Menu.Item
            key="cancel"
            onClick={() => {
              setCancellingSubscription(model);
            }}
          >
            Cancel
          </Menu.Item>
        )}
        {user.canRefundTransaction && actions.includes("revokeEntitlement") && (
          <Menu.Item
            key="revokeEntitlement"
            onClick={() => {
              setRevokingSubscription(model);
            }}
          >
            Revoke entitlement
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <div>
      <Table
        loading={{
          spinning: isLoading,
        }}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        columns={columnsToDisplay}
        dataSource={subscriptions}
        rowKey={(row) => row.subscriptionId}
        pagination={{
          showTotal: getTotalDisplay,
          total: subscriptions.length,
          showSizeChanger: true,
        }}
      ></Table>
      <CancelSubscriptionModal
        auth0Id={auth0Id}
        subscription={cancellingSubscription}
        visible={cancellingSubscription.subscriptionId !== undefined}
        closeModal={() => setCancellingSubscription({} as SubscriptionModel)}
        onFinish={refreshList}
      />
      <RevokeEntitlementModal
        auth0Id={auth0Id}
        subscription={revokingSubscription}
        visible={revokingSubscription.subscriptionId !== undefined}
        closeModal={() => setRevokingSubscription({} as SubscriptionModel)}
        onFinish={refreshList}
      />
      <PauseSubscriptionModal
        auth0Id={auth0Id}
        subscription={pausingSubscription}
        visible={pausingSubscription.subscriptionId !== undefined}
        closeModal={() => setPausingSubscription({} as SubscriptionModel)}
        onFinish={refreshList}
      />
      <RestartSubscriptionModal
        auth0Id={auth0Id}
        subscription={restartingSubscription}
        visible={restartingSubscription.subscriptionId !== undefined}
        closeModal={() => {
          setRestartingSubscription({} as SubscriptionModel);
        }}
        onFinish={refreshList}
      />
      {upgradingSubscription.subscriptionId && (
        <UpgradeSubscriptionModal
          auth0Id={auth0Id}
          email={email}
          subscription={upgradingSubscription}
          visible
          closeModal={() => {
            setUpgradingSubscription({} as SubscriptionModel);
          }}
        />
      )}
      {downgradingSubscription.subscriptionId && (
        <DowngradeSubscriptionModal
          auth0Id={auth0Id}
          email={email}
          subscription={downgradingSubscription}
          visible
          closeModal={() => {
            setDowngradingSubscription({} as SubscriptionModel);
          }}
        />
      )}
      {applyPromoCodeSubscription.subscriptionId && (
        <ApplyPromoCodeModal
          auth0Id={auth0Id}
          subscription={applyPromoCodeSubscription}
          visible
          closeModal={() => {
            setApplyPromoCodeSubscription({} as SubscriptionModel);
          }}
          onFinish={refreshList}
        />
      )}
      {resumingSubscription.subscriptionId && (
        <ResumeSubscriptionModal
          auth0Id={auth0Id}
          subscription={resumingSubscription}
          closeModal={() => setResumingSubscription({} as SubscriptionModel)}
          onFinish={refreshList}
          visible
        />
      )}
      {extendBillingDateSubscription.subscriptionId && (
        <ExtendBillingDateModal
          auth0Id={auth0Id}
          subscription={extendBillingDateSubscription}
          closeModal={() => setExtendBillingDateSubscription({} as SubscriptionModel)}
          onFinish={refreshList}
          visible
        />
      )}
      {switchSubscription.subscriptionId && (
        <SwitchSubscriptionModal
          email={email}
          auth0Id={auth0Id}
          subscription={switchSubscription}
          visible
          closeModal={() => setSwitchSubscription({} as SubscriptionModel)}
        />
      )}
    </div>
  );
}
