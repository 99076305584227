import { Col, Form, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const PromotionCodeText = () => {
  const PromotionCodeTextTooltip = {
    title: (
      <div>
        If both English and Spanish are not populated, then a default value will be displayed. If Spanish text is not
        configured, then display English.
      </div>
    ),
    icon: <InfoCircleOutlined />,
  };

  return (
    <>
      <Col span={12}>
        <Form.Item
          label="Promotion code text(English)"
          name="codeTextEn"
          rules={[{ type: "string", max: 100 }]}
          tooltip={PromotionCodeTextTooltip}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Promotion code text(Spanish)"
          name="codeTextEs"
          rules={[{ type: "string", max: 100 }]}
          tooltip={PromotionCodeTextTooltip}
        >
          <Input />
        </Form.Item>
      </Col>
    </>
  );
};

export default PromotionCodeText;
