import React from "react";
import { Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DATETIME_FORMAT_WITH_MINUTE } from "../../../../../constants/common";
import PromotionCodeText from "./PromotionCodeText";
import styles from "./index.module.scss";
import DebounceSearchInput from "../../../../../components/DebounceSearchInput";
import { DropDown } from "../../../../../assets/icons";
import { DescriptionAndDisclosureContent } from "../DescriptionAndDisclosure";
import { Option } from "./index";
import { EComProductModel } from "../../../../../types/model/eComProduct";

interface PromotionDetailsFormForEComSourceProps {
  planOptionList: Option[];
  setPlanOptionList: React.Dispatch<React.SetStateAction<Option[]>>;
  handleVariantsSearch: (search: string) => Promise<Option[]>;
  giftPlanList: EComProductModel[];
}

const PromotionDetailsFormForEComSource = ({
  planOptionList,
  setPlanOptionList,
  handleVariantsSearch,
  giftPlanList,
}: PromotionDetailsFormForEComSourceProps) => {
  const { RangePicker } = DatePicker;

  return (
    <>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Promotion code" name="code" rules={[{ type: "string", max: 25, required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Promotion name" name="name" rules={[{ type: "string", max: 100, required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Time range" name="timeRange" rules={[{ required: true }]} validateTrigger={["onChange"]}>
            <RangePicker
              style={{ width: "100%" }}
              showTime={{ format: "HH:mm" }}
              format={DATETIME_FORMAT_WITH_MINUTE}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Promotion type" name="type" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <PromotionCodeText />
      </Row>

      <Divider className={styles.divider} />

      <Row gutter={32}>
        <Col span={18}>
          <Form.Item label="Base plan" name="basePlanId" rules={[{ required: true, type: "string" }]}>
            <DebounceSearchInput
              showSearch
              allowClear
              existedOptions={planOptionList}
              fetchOptions={handleVariantsSearch}
              onClear={() => {
                setPlanOptionList([]);
              }}
              placeholder="Search PID or plan name"
              suffixIcon={<SearchOutlined className={styles.searchIcon} />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Gift plan" name="giftPlanId" rules={[{ required: true, type: "string" }]}>
            <Select
              allowClear
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              suffixIcon={<DropDown />}
              placeholder="Please select applied gift plan"
              options={giftPlanList.map(({ id, planName }) => ({
                label: planName,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider className={styles.divider} />

      <Form.Item noStyle shouldUpdate>
        <header className={styles.subtitle}>Description & Disclosure</header>
        <DescriptionAndDisclosureContent editable={true} />
      </Form.Item>
    </>
  );
};

export default PromotionDetailsFormForEComSource;
