import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Dropdown, Menu, message } from "antd";
import { More } from "assets/icons";
import { revokeEntitlement } from "../../../services";
import EditEntitlement from "../EditEntitlement";
import { Modal } from "../../../components";
import { fetchEntitlementList } from "../customerSlice";
import { useAppDispatch } from "../../../app/store";
import { sources, statuses } from "../../entitlements/RList/utils";
import useAppAuth0 from "../../../hooks/useAppAuth0";

interface ActionPros {
  record: {
    entitlementId: string;
    expirationDate: number;
    isbn: string;
    productName: string;
    source: keyof typeof sources;
    status: keyof typeof statuses;
    isSubscription: boolean;
  };
}

export default function Actions({ record }: ActionPros) {
  const { entitlementId, expirationDate, isbn, productName, source, status, isSubscription } = record;
  const { customerId } = useParams<{
    customerId: string;
  }>();
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { user } = useAppAuth0();

  const isShowEdit = sources[source] === sources.ADMIN && statuses[status] === statuses.ACTIVE;
  const isActiveOkta = sources[source] === sources.OKTA && statuses[status] === statuses.ACTIVE;
  const isActiveAmazon = sources[source] === sources.AMAZON && statuses[status] === statuses.ACTIVE;

  const isShowRevoke =
    isShowEdit ||
    isActiveOkta ||
    isActiveAmazon ||
    (!isSubscription && sources[source] === sources.ECOM && statuses[status] === statuses.ACTIVE);

  function revoke() {
    Modal.confirm({
      title: "Revoke subscription",
      content: "Are you sure you want to revoke this entitlement?",
      onOk: () => {
        revokeEntitlement(entitlementId)
          .then(() => {
            dispatch(fetchEntitlementList(Number(customerId)))
              .then(unwrapResult)
              .catch(message.error);
          })
          .catch((error) => {
            console.log(error);
            message.error(error);
          });
      },
      okText: "Revoke",
    });
  }

  return (
    <div style={{ cursor: isShowEdit || isShowRevoke ? "pointer" : "not-allowed" }}>
      {isShowEdit || isShowRevoke ? (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              {isShowEdit && user.canUpdateB2CCustomerEntitlement && (
                <Menu.Item key="edit" onClick={() => setIsModalVisible(true)}>
                  Edit
                </Menu.Item>
              )}
              {isShowRevoke && user.canRevokeB2CCustomerEntitlement && (
                <Menu.Item key="revoke" onClick={revoke}>
                  Revoke
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <More />
        </Dropdown>
      ) : (
        "-"
      )}
      <EditEntitlement
        isbn={isbn}
        productName={productName}
        entitlementId={entitlementId}
        expirationDate={expirationDate}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
}
