import toUpper from "lodash/toUpper";
import { Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import { SelectValue } from "antd/es/select";
import { DropDown } from "../../../../../assets/icons";
import styles from "./index.module.scss";
import PromotionCodeText from "./PromotionCodeText";
import { DATETIME_FORMAT_WITH_MINUTE } from "../../../../../constants/common";
import { PromotionStatus, RecurlyCouponModel } from "../../../../../types/model/promotionV2";
import { BasePriceModel } from "../../../../../types/model/price";
import { Option } from "./index";

interface PromotionDetailsFormForRecurlySourceProps {
  recurlyCoupons: RecurlyCouponModel[];
  basePriceList: BasePriceModel[];
  countryList: Option[];
  handlePromotionCodeChange: (selectedRecurlyCouponCode: SelectValue) => void;
}

const PromotionDetailsFormForRecurlySource = ({
  recurlyCoupons,
  basePriceList,
  countryList,
  handlePromotionCodeChange,
}: PromotionDetailsFormForRecurlySourceProps) => {
  const { RangePicker } = DatePicker;

  const getDisplayRecurlyCouponCode = (code: string, status: PromotionStatus): string => {
    return `${toUpper(code)} ${status === PromotionStatus.EXPIRED ? "(Expired)" : ""}`;
  };

  return (
    <>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Promotion code" name="displayPromotionCode" rules={[{ required: true }]}>
            <Select
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              showSearch
              suffixIcon={<DropDown />}
              placeholder="Please select a promotion code"
              options={recurlyCoupons.map(({ id, code, status }) => ({
                label: getDisplayRecurlyCouponCode(code, status),
                value: id,
              }))}
              onChange={(e) => handlePromotionCodeChange(e)}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate name="recurlyCouponId">
            <Input hidden />
          </Form.Item>
          <Form.Item noStyle shouldUpdate name="code">
            <Input hidden />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Promotion name" name="name" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Time range" name="timeRange" rules={[{ required: true }]} validateTrigger={["onChange"]}>
            <RangePicker
              disabled
              style={{ width: "100%", backgroundColor: "#f5f5f5", borderColor: "#d9d9d9" }}
              showTime={{ format: "HH:mm" }}
              format={DATETIME_FORMAT_WITH_MINUTE}
              placeholder={["", "-"]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Promotion type" name="type" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Discount" name="discount" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>
        <PromotionCodeText />
      </Row>

      <Divider className={styles.divider} />

      <Row gutter={32}>
        <Col span={24}>
          <Form.Item label="Applied base price" name="basePriceIds" rules={[{ required: true, type: "array" }]}>
            <Select
              allowClear
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              suffixIcon={<DropDown />}
              mode="multiple"
              placeholder="Please select applied base price"
              options={basePriceList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="countryIds" label="Applied country/region">
            <Select
              allowClear
              optionFilterProp="label"
              getPopupContainer={(trigger) => trigger.parentNode}
              showArrow
              suffixIcon={<DropDown />}
              mode="multiple"
              placeholder="Please select applied country/region"
              options={countryList}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider className={styles.divider} />
    </>
  );
};

export default PromotionDetailsFormForRecurlySource;
