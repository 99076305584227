import { DeltaStatic } from "quill";
import RichTextEditor from "../RichTextEditor";

interface EComRichTextInputProps {
  editable: boolean;
  value: DeltaStatic;
  onChange?: (value: DeltaStatic) => void;
  bounds?: string | HTMLElement;
}

function EComRichTextInput({ bounds, editable, value, onChange = () => {} }: EComRichTextInputProps) {
  return (
    <RichTextEditor
      bounds={bounds}
      value={value}
      onChange={(content, delta, source, editor) => onChange(editor.getContents())}
      readonly={!editable}
    />
  );
}

export default EComRichTextInput;
