import { PaymentMethodDto } from "../../../dto/response/paymentMethod";
import { BillingAddress, CreditCardType, PaymentMethod, PaymentMethodType } from "../../../model/paymentMethod";
import { BillingAddressUpdateRequest } from "../../../dto/request/paymentMethod";

export function transformPaymentMethod(dto: PaymentMethodDto): PaymentMethod {
  return {
    id: dto.id,
    created: dto.created,
    type: dto.type as PaymentMethodType,
    default: dto.default,
    account: dto.account,
    cardType: dto.card_type as CreditCardType,
    expirationDate: dto.expiration_date,
    billingAddress: dto.billing_address
      ? {
          postalCode: dto.billing_address.postal_code,
          district: dto.billing_address.district,
          country: dto.billing_address.country,
        }
      : undefined,
  };
}

export function transformToBillingAddressUpdateRequest(model: BillingAddress): BillingAddressUpdateRequest {
  return {
    billing_address: {
      postal_code: model.postalCode,
      country: model.country,
      district: model.district,
    },
  };
}
