import { supportLocalTabs } from "../../../utils/locale";
import { ContentBase } from "../../../types/model/description";
import { Currency, PromotionDiscountType, PromotionStatus } from "../../../types/model/promotionV2";

export const getDisplayPromotionDiscountType = (promotionDiscountType: PromotionDiscountType) => {
  switch (promotionDiscountType) {
    case PromotionDiscountType.PERCENTAGE_OFF:
      return "Percentage off";
    case PromotionDiscountType.AMOUNT_OFF:
      return "Amount off";
    case PromotionDiscountType.BUY_ONE_GIFT_ONE:
      return "Buy one gift one";
    default:
      return;
  }
};

export const getDisplayPromotionStatus = (promotionStatus?: PromotionStatus) => {
  switch (promotionStatus) {
    case PromotionStatus.NOT_STARTED:
      return "Not started";
    case PromotionStatus.REDEEMABLE:
      return "Redeemable";
    case PromotionStatus.EXPIRED:
      return "Expired";
    case PromotionStatus.MAXED_OUT:
      return "Maxed out";
    default:
      return;
  }
};

export const getDisplayDiscount = (
  discountType: PromotionDiscountType,
  percent?: number,
  currencies?: Currency[]
): string => {
  return discountType === PromotionDiscountType.PERCENTAGE_OFF
    ? `${percent!.toFixed(2)}%`
    : currencies!.map(({ currency, amount }) => `${currency} ${amount.toFixed(2)}`).join(" / ");
};

export const transformDescriptionDisclosure = (values: any, contentKey: string) => {
  return Object.keys(values)
    .filter((key) => key.includes(contentKey))
    .map((key) => {
      const locale = key.split(contentKey)[0];
      return {
        ...supportLocalTabs.find((item) => item.key === locale),
        required: false,
        content: values[key],
      } as ContentBase;
    });
};
