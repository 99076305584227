import { CreditCardType, PaymentMethodType } from "./paymentMethod";

export enum TransactionStatus {
  COMPLETED = "COMPLETED",
  REFUNDED = "REFUNDED",
  PROCESSING = "PROCESSING",
  OTHERS = "OTHERS",
}

export const TransactionStatusMap = {
  [TransactionStatus.COMPLETED]: "Completed",
  [TransactionStatus.REFUNDED]: "Refunded",
  [TransactionStatus.PROCESSING]: "Processing",
  [TransactionStatus.OTHERS]: "Others",
};

export enum ProductPlanType {
  SUBSCRIPTION_NO_TRIAL = "subscription_no_trial",
  SUBSCRIPTION_FREE_TRIAL = "subscription_free_trial",
  LIFE_TIME = "life_time",
  GIFT_SUBSCRIPTION = "gift_subscription",
  GIFT_CREDIT = "gift_credit",
  OTHERS = "others",
}

export const PRODUCT_PLAN_TYPE_MAP = {
  [ProductPlanType.LIFE_TIME]: "Lifetime",
  [ProductPlanType.SUBSCRIPTION_FREE_TRIAL]: "Subscription",
  [ProductPlanType.SUBSCRIPTION_NO_TRIAL]: "Subscription",
  [ProductPlanType.GIFT_SUBSCRIPTION]: "Gift Subscription",
  [ProductPlanType.GIFT_CREDIT]: "Gift Card",
  [ProductPlanType.OTHERS]: "Others",
};

export enum TransactionSource {
  ECOM = "eCom",
  NAVIGA = "Naviga",
  DEMANDWARE = "Demandware",
  RECURLY = "Recurly",
  OTHERS = "Others",
}

interface Currency {
  code: string;
  symbol: string;
}

export interface TransactionResponse {
  id: string;
  created_at: string;
  status: TransactionStatus;
  source: TransactionSource;
  subscription_id?: string;
  subscription_info?: {
    startedAt: string;
    endedAt: string;
  };
  pid: string;
  product_info: {
    name: string;
    type: ProductPlanType;
  };
  price_info: {
    tax_type: "exclusive_sales" | "inclusive_sales" | "no_tax";
    tax: number;
    amount: number;
    account_amount: number;
    original_price: number;
    discount: number;
    credit: number;
    total: number;
  };
  refund_info?: {
    amount: number;
    account_amount: number;
    createdAt: string;
    total: number;
  };
  payment_method?: {
    given_name: string;
    family_name: string;
    account: string;
    billing_address: {
      postal_code: string;
      country: string;
      district?: string;
    };
    type: PaymentMethodType;
    card_type?: CreditCardType;
  };
  currency: Currency;
}

export interface TransactionPrice {
  accountAmount: string;
  amount: string;
  tax: string;
  originalPrice: string;
  taxType: "exclusive_sales" | "inclusive_sales" | "no_tax";
  total: string;
  credit?: string;
  discount?: string;
}

export interface TransactionRefund {
  accountAmount: string;
  amount: string;
  total: string;
  created: string;
}

export interface PaymentInformation {
  country: string;
  district?: string;
  postalCode: string;
  type: PaymentMethodType;
  account: string;
  cardType?: CreditCardType | string;
  givenName: string;
  familyName: string;
}

export interface TransactionSubscription {
  id: string;
  startTime: string;
  endTime: string;
}

export interface TransactionModel {
  id: string;
  source: TransactionSource;
  status: TransactionStatus;
  productPlan: {
    name: string;
    pid: string;
  };
  billingPlan: {
    type: ProductPlanType;
  };
  createdTime: string;
  currency: Currency;
  price: TransactionPrice;
  refundPrice?: TransactionRefund;
  subscription?: TransactionSubscription;
  paymentInformation?: PaymentInformation;
}

export function getTransactionAmountText(transaction: TransactionModel) {
  const accountAmount = transaction.price.accountAmount;
  const amount = `${transaction.currency.symbol}${transaction.price.amount}`;

  if (Number(accountAmount) > 0) {
    return `${amount} + ${transaction.currency.symbol}${accountAmount}(Balance)`;
  }

  return amount;
}
