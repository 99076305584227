import React from "react";
import { Col, Divider, Form, FormInstance, Row, Tabs } from "antd";
import { DisplayInput } from "../../../../components";
import styles from "./index.module.scss";
import { ContentBase } from "../../../../types/model/description";
import { EComRichTextDescription, EComRichTextInput } from "../../../../components/EComRichText";
const { TabPane } = Tabs;

interface IDescriptionDetailsViewProps {
  form: FormInstance;
  contents: ContentBase[];
}

function DescriptionDetailsView({ form, contents }: IDescriptionDetailsViewProps) {
  return (
    <Form className={styles.descriptionDetailContainer} form={form} layout="vertical">
      <Row>
        <Col span={24}>
          <Form.Item name="name" label="Description template name">
            <DisplayInput />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <div className={styles.tabCardContainer}>
        <Tabs type="card">
          {contents.map((item: ContentBase) => (
            <TabPane tab={item.label} key={item.key}>
              <Form.Item name={`${item.locale}Content`} label="Description template Content">
                <DisplayInput render={(x) => <EComRichTextInput editable={false} value={x} />} />
              </Form.Item>
              <EComRichTextDescription showOflessons={true} />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </Form>
  );
}

export default DescriptionDetailsView;
