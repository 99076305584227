import React, { useEffect } from "react";
import { Collapse, Form } from "antd";
import Icon from "@ant-design/icons";
import { DescriptionDisclosure } from "../../../../../types/model/promotionV2";
import styles from "./DescriptionAndDisclosureCollapse.module.scss";
import * as Icons from "../../../../../assets/icons";
import ActionMenu from "./ActionMenu";
import DescriptionAndDisclosureContentView from "./DescriptionAndDisclosureContentView";
import { DisplayInput } from "../../../../../components";

interface DescriptionAndDisclosureCollapseProps {
  editable: boolean;
  descriptionDisclosure: DescriptionDisclosure;
  defaultActiveDescriptionDisclosure: DescriptionDisclosure;
  onEdit: () => void;
  onDelete: () => void;
}

export default function DescriptionAndDisclosureCollapse({
  editable,
  descriptionDisclosure,
  defaultActiveDescriptionDisclosure,
  onEdit,
  onDelete,
}: DescriptionAndDisclosureCollapseProps) {
  const [form] = Form.useForm();
  useEffect(() => {
    const { billingPlanName, description, disclosure } = descriptionDisclosure;

    if (billingPlanName) {
      form.setFieldsValue({
        billingPLanName: descriptionDisclosure.billingPlanName,
      });
    }
    if (description) {
      description.forEach((item) => {
        form.setFieldsValue({
          [`${item.locale}DescriptionContent`]: item.content,
        });
      });
    }
    if (disclosure) {
      disclosure.forEach((item) => {
        form.setFieldsValue({
          [`${item.locale}DisclosureContent`]: item.content,
        });
      });
    }
  }, [descriptionDisclosure, form]);

  return (
    <Collapse
      collapsible="header"
      className={styles.collapseCustomCollapse}
      expandIcon={({ isActive }) => (
        <Icon component={Icons.CareRight} className={styles.icon} rotate={isActive ? 90 : 0} />
      )}
      defaultActiveKey={defaultActiveDescriptionDisclosure.billingPlanId!}
    >
      <Collapse.Panel
        key={descriptionDisclosure.billingPlanId!}
        header={
          <div className={styles.collapsePanelHeader}>
            <span className={styles.headerText}>{descriptionDisclosure.billingPlanName}</span>
          </div>
        }
        extra={editable ? <ActionMenu onDelete={onDelete} onEdit={onEdit} /> : null}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Billing plan" name="billingPLanName" className={styles.boldFormItem}>
            <DisplayInput />
          </Form.Item>

          <DescriptionAndDisclosureContentView />
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
}
