import { Button, Space } from "antd";

interface EditButtonsProps {
  onCancel: () => void;
  onSave: () => void;
}

export default function EditButtons({ onCancel, onSave }: EditButtonsProps) {
  return (
    <Space size={8}>
      <Button type="default" shape="round" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={onSave}>
        Save
      </Button>
    </Space>
  );
}
