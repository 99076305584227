import React, { useEffect } from "react";
import { useParams } from "react-router";
import { isNil } from "lodash";
import { Divider, Form, Tooltip } from "antd";
import Icon from "@ant-design/icons";
import Modal from "components/Modal";
import { unwrapResult } from "@reduxjs/toolkit";
import { DisplayInput, message } from "components";
import { useAppDispatch } from "app/store";
import { span } from "utils/style";
import { SendEmail } from "assets/icons";
import { EMPTY } from "constants/common";
import { GroupAdmin, OrganizationModal } from "types/model/organization";
import { fetchOrganization, sendEmail } from "../organizationSlice";
import styles from "../OrganizationForm/OrganizationForm.module.scss";
import { DeliveryStatus } from "../../../types/model/global";

interface OrganizationInfoProps {
  data: OrganizationModal | undefined;
}

function OrganizationInfoForm({ data }: OrganizationInfoProps) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { organizationId } = useParams<{ organizationId: string }>();

  useEffect(() => form.resetFields(), [form, data]);

  const handleSendEmail = (email: string) => {
    Modal.confirm({
      title: "Send email",
      content: `Are you sure you want to send admin on-boarding email to ${email}?`,
      okText: "Send",
      onOk: () =>
        dispatch(sendEmail({ id: organizationId, email: email }))
          .then(unwrapResult)
          .then(() => {
            dispatch(fetchOrganization(organizationId)).then(unwrapResult).catch(message.error);
          })
          .catch(message.error),
    });
  };

  return (
    <Form form={form} initialValues={data} layout="inline" labelAlign={"left"} className={styles.form}>
      <Form.Item label="Organization name" name="name" style={span(12)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item style={span(12)} />
      <Form.Item label="Allowed to create subgroup" name="isCreatingSubgroupEnabled" style={span(8)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item label="Progress Reporting" name="isAnalyticsReportingEnabled" style={span(8)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item label="Create seat user account" name="isCreatingAuth0AccountForSeatUserEnabled" style={span(8)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item label="Allow cookie tracking" name="isAnalyticsUserTrackingEnabled" style={span(8)}>
        <DisplayInput />
      </Form.Item>
      <Form.Item label="Description" name="description" style={span(24)}>
        <DisplayInput />
      </Form.Item>

      <Divider style={{ margin: "8px 0 21px 0" }} />

      {data?.groupAdmins?.map((groupAdmin: GroupAdmin, index: number) => {
        const { email, name, telephone, hasSentEmail, deliveryStatus } = groupAdmin;
        const hasSent = hasSentEmail && (isNil(deliveryStatus) || deliveryStatus === DeliveryStatus.DELIVERY);
        const isSending = hasSentEmail && deliveryStatus === DeliveryStatus.SENDING;
        const isFailed =
          hasSentEmail && (deliveryStatus === DeliveryStatus.BOUNCE || deliveryStatus === DeliveryStatus.FAILED);
        return (
          <React.Fragment key={index}>
            <Form.Item label={index === 0 ? "Admin email" : ""} style={span(12)}>
              <span>{email || EMPTY}</span>
              {email && (
                <>
                  <Tooltip placement="top" title="Send admin on-boarding email">
                    <Icon component={SendEmail} style={{ marginLeft: "10px" }} onClick={() => handleSendEmail(email)} />
                  </Tooltip>
                  {(hasSent || isSending || isFailed) && (
                    <i
                      style={{
                        marginLeft: "10px",
                        backgroundColor: isFailed ? "#fff0f0" : "#f3f4ff",
                        padding: "8px",
                        color: isFailed ? "#f00" : undefined,
                      }}
                    >
                      {hasSent && "Email sent"}
                      {isSending && "Email delivering"}
                      {isFailed && "Email failed to send"}
                    </i>
                  )}
                </>
              )}
            </Form.Item>
            <Form.Item label={index === 0 ? "Admin name" : ""} style={span(6)}>
              <DisplayInput value={name} />
            </Form.Item>
            <Form.Item label={index === 0 ? "Phone number" : ""} style={span(6)}>
              <DisplayInput value={telephone} />
            </Form.Item>
          </React.Fragment>
        );
      })}
      <Form.Item label="Allowed domains" style={span(24)}>
        <DisplayInput value={data?.allowedLoginDomains} />
      </Form.Item>
    </Form>
  );
}

export default OrganizationInfoForm;
