import React from "react";
import { Col, ConfigProvider, Divider, Form, FormInstance, Input, Row, Tabs } from "antd";
import { DeltaStatic } from "quill";
import { validateMessages } from "../../../../constants/common";
import { DESCRIPTION_MAX_LENGTH } from "../../Disclosure/common";
import { isDeltaEmpty } from "../../../../utils/richTextEditor";
import { LocaleType } from "../../../../utils/locale";
import styles from "./index.module.scss";
import { ContentBase } from "../../../../types/model/description";
import { EComRichTextDescription, EComRichTextInput } from "../../../../components/EComRichText";

const { TabPane } = Tabs;

interface IDescriptionTemplateFormProps {
  form: FormInstance;
  contents: ContentBase[];
  updateContents: (values: ContentBase[]) => void;
}

function DescriptionTemplateForm({ form, contents, updateContents }: IDescriptionTemplateFormProps) {
  const updateContent = (value: DeltaStatic, locale: LocaleType) => {
    const updatedContents = contents.map((content) =>
      content.locale === locale ? { ...content, content: value } : content
    );
    updateContents(updatedContents);
  };

  return (
    <ConfigProvider form={{ validateMessages }}>
      <Form form={form} validateTrigger={"onBlur"} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Description template name"
              name="name"
              rules={[{ required: true, type: "string", max: 100 }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <div className={styles.tabCardContainer}>
          <Tabs type="card">
            {contents.map((item: ContentBase) => (
              <TabPane tab={item.label} key={item.key}>
                <Form.Item
                  label="Description template Content"
                  name={`${item.locale}Content`}
                  rules={[
                    {
                      required: item.required,
                      transform: (value) => (isDeltaEmpty(value) ? "" : JSON.stringify(value)),
                      type: "string",
                      max: DESCRIPTION_MAX_LENGTH,
                    },
                  ]}
                >
                  <EComRichTextInput
                    bounds={item.key}
                    editable={true}
                    value={item.content}
                    onChange={(value) => updateContent(value, item.locale)}
                  />
                </Form.Item>
                <EComRichTextDescription showOflessons={true} />
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Form>
    </ConfigProvider>
  );
}

export default DescriptionTemplateForm;
