import { DeltaStatic } from "quill";
import React, { useEffect, useState } from "react";
import { Button, Space, Modal, Form, Select, ConfigProvider } from "antd";
import sortBy from "lodash/sortBy";
import { DescriptionDisclosure } from "../../../../../types/model/promotionV2";
import styles from "./DescriptionAndDisclosure.module.scss";
import DescriptionAndDisclosureCollapse from "./DescriptionAndDisclosureCollapse";
import DescriptionAndDisclosureContent from "./DescriptionAndDisclosureContent";
import EmptyContent from "../../../../../components/EmptyContent";
import { DropDown } from "../../../../../assets/icons";
import * as eComProductAPI from "../../../../../services/eComProduct";
import { BillingPlan } from "../../../../../types/model/eComProduct";
import { validateMessages } from "../../../../../constants/common";
import { bold } from "../../../../../utils";
import { Modal as CustomModal } from "../../../../../components";
import { transformDescriptionDisclosure } from "../../util";

interface DescriptionAndDisclosureProps {
  editable: boolean;
  descriptionDisclosures: DescriptionDisclosure[];
  updateDescriptionDisclosures?: (descriptionDisclosures: DescriptionDisclosure[]) => void;
}

export default function DescriptionAndDisclosure({
  editable,
  descriptionDisclosures,
  updateDescriptionDisclosures,
}: DescriptionAndDisclosureProps) {
  const [currentDescriptionDisclosureForm] = Form.useForm();
  const [billingPlans, setBillingPlans] = useState<BillingPlan[]>([]);
  const [usedBillingPlanIds, setUsedBillingPlanIds] = useState<BillingPlan[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUpdateDescriptionDisclosure, setCurrentUpdateDescriptionDisclosure] = useState<DescriptionDisclosure>();

  useEffect(() => {
    eComProductAPI.fetchBillingPlans().then((billingPlans) => setBillingPlans(billingPlans));
  }, []);

  useEffect(() => {
    const existBillingPlanIds = descriptionDisclosures.map(({ billingPlanId }) => billingPlanId);
    const existBillingBillingPlans = billingPlans.filter(({ id }) => existBillingPlanIds.includes(id));
    setUsedBillingPlanIds(existBillingBillingPlans);
  }, [billingPlans, descriptionDisclosures]);

  const renderDescriptionDisclosureList = () => {
    const descriptionDisclosuresFilterByBillingId = descriptionDisclosures.filter(({ billingPlanId }) => billingPlanId);
    if (descriptionDisclosuresFilterByBillingId.length === 0) {
      return (
        <section className={styles.emptyPlan}>
          <EmptyContent displayTexts={["No Description & Disclosure yet.", "Please add one."]} />
        </section>
      );
    }

    const sortDescriptionDisclosures = sortBy(descriptionDisclosuresFilterByBillingId, ["billingPlanName"]);
    return (
      <div className={styles.container}>
        {sortDescriptionDisclosures.map((descriptionDisclosure) => (
          <DescriptionAndDisclosureCollapse
            key={descriptionDisclosure.billingPlanId}
            editable={editable}
            defaultActiveDescriptionDisclosure={sortDescriptionDisclosures[0]}
            descriptionDisclosure={descriptionDisclosure}
            onEdit={() => handleEdit(descriptionDisclosure)}
            onDelete={() => handleDelete(descriptionDisclosure)}
          />
        ))}
      </div>
    );
  };

  const handleAdd = () => {
    setIsModalVisible(true);
    setCurrentUpdateDescriptionDisclosure(undefined);
  };

  const handleEdit = (descriptionDisclosure: DescriptionDisclosure) => {
    setCurrentUpdateDescriptionDisclosure(descriptionDisclosure);
    currentDescriptionDisclosureForm.setFieldsValue({
      billingPlanId: descriptionDisclosure.billingPlanId,
      ...descriptionDisclosure?.description?.reduce((acc, item) => {
        acc[`${item.locale}DescriptionContent`] = item.content;
        return acc;
      }, {} as { [key: string]: DeltaStatic }),
      ...descriptionDisclosure?.disclosure?.reduce((acc, item) => {
        acc[`${item.locale}DisclosureContent`] = item.content;
        return acc;
      }, {} as { [key: string]: DeltaStatic }),
    });
    setIsModalVisible(true);
  };

  const handleDelete = (descriptionDisclosure: DescriptionDisclosure) => {
    CustomModal.confirm({
      title: "Delete Description and Disclosure",
      okText: "Confirm",
      content: bold`Are you sure you want to delete the ${descriptionDisclosure.billingPlanName!}?`,
      onOk: () => {
        return new Promise<void>((resolve, reject) => {
          const updatedDisclosures = descriptionDisclosures.filter(
            ({ billingPlanId }) => billingPlanId !== descriptionDisclosure.billingPlanId
          );
          updateDescriptionDisclosures?.(updatedDisclosures);

          resolve();
        });
      },
    });
  };

  const handleSave = () => {
    currentDescriptionDisclosureForm.validateFields().then((values) => {
      const updatedDescriptionDisclosure: DescriptionDisclosure = {
        billingPlanId: values.billingPlanId,
        billingPlanName: billingPlans.find((plan) => plan.id === values.billingPlanId)!.name,
        description: transformDescriptionDisclosure(values, "DescriptionContent"),
        disclosure: transformDescriptionDisclosure(values, "DisclosureContent"),
      };
      updateDescriptionDisclosures?.([
        ...descriptionDisclosures.filter(
          ({ billingPlanId }) => billingPlanId !== currentUpdateDescriptionDisclosure?.billingPlanId
        ),
        updatedDescriptionDisclosure,
      ]);

      setIsModalVisible(false);
      currentDescriptionDisclosureForm.resetFields();
    });
  };

  return (
    <>
      <div className={styles.subtitle}>
        <span>Description & Disclosure</span>
        {editable && (
          <Button type="default" shape="round" onClick={handleAdd}>
            Add new description & disclosure
          </Button>
        )}
      </div>
      {renderDescriptionDisclosureList()}
      <Modal
        width={1062}
        bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }}
        centered={true}
        title="Add new description & disclosure"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          currentDescriptionDisclosureForm.resetFields();
        }}
        okText="Save"
        onOk={handleSave}
        maskClosable={false}
        cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
        className={styles.modal}
        destroyOnClose={true}
      >
        <Space direction="vertical" className={styles.modalContent}>
          <ConfigProvider form={{ validateMessages }}>
            <Form form={currentDescriptionDisclosureForm} layout="vertical">
              <Form.Item label="Billing plan" name="billingPlanId" rules={[{ required: true }]}>
                <Select
                  allowClear
                  optionFilterProp="label"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  showArrow
                  showSearch
                  suffixIcon={<DropDown />}
                  placeholder="Please select billing plan"
                  options={billingPlans.map(({ id, name }) => ({
                    label: name,
                    value: String(id),
                    disabled: usedBillingPlanIds.some((item) => item.id === id),
                  }))}
                  className={styles.selectDropdown}
                />
              </Form.Item>

              <DescriptionAndDisclosureContent
                descriptionDisclosure={currentUpdateDescriptionDisclosure}
                editable={true}
              />
            </Form>
          </ConfigProvider>
        </Space>
      </Modal>
    </>
  );
}
