import {
  SubscriptionModel,
  SubscriptionResponse,
  SubscriptionResponseStatus,
  SubscriptionSource,
  SubscriptionStatus,
  SubscriptionUpsellDowngradeListItem,
  SubscriptionUpsellDowngradeListResponse,
} from "../model/subscriptions";

export function transformSubscriptions(dto: SubscriptionResponse): SubscriptionModel {
  const subscriptionModel: SubscriptionModel = {
    title: dto.title,
    status: getStatus(dto),
    paymentMethod: dto.payment_method
      ? {
          cardType: dto.payment_method.card_type,
          account: dto.payment_method.account,
          billingAddress: {
            country: dto.payment_method.billing_address.country,
          },
        }
      : undefined,
    source: dto.source,
    subscriptionId: dto.subscription_id,
    startDate: dto.start_date,
    endDate: dto.next_billing?.date ? undefined : dto.end_date,
    pauseStartDate: dto.paused_start_date,
    pid: dto.pid,
    actions: [],
  };

  if (dto.grace_period_end_date) {
    subscriptionModel.endDate = dto.grace_period_end_date;
  }

  if (dto.next_billing) {
    subscriptionModel.nextBilling = {
      date: dto.next_billing.date,
      amount: dto.next_billing.amount,
      currencySymbol: dto.next_billing.currency_symbol,
    };
  }

  subscriptionModel.actions = getActionsByStatusAndSource(subscriptionModel.status, subscriptionModel.source);

  return subscriptionModel;
}

const getStatus = (dto: SubscriptionResponse): SubscriptionStatus => {
  if (dto.grace_period_end_date) {
    return SubscriptionStatus.GRACE_PERIOD;
  }
  if (dto.free_trail_end_date) {
    return SubscriptionStatus.FREE_TRIAL;
  }
  if (dto.status === SubscriptionResponseStatus.CANCELLED) {
    return SubscriptionStatus.CANCELLED;
  }
  if (dto.status === SubscriptionResponseStatus.ACTIVE) {
    return SubscriptionStatus.ACTIVE;
  }
  if (dto.status === SubscriptionResponseStatus.PENDING_CANCEL) {
    return SubscriptionStatus.PENDING_CANCEL;
  }
  if (dto.status === SubscriptionResponseStatus.PENDING_PAUSE) {
    return SubscriptionStatus.PENDING_PAUSE;
  }
  if (dto.status === SubscriptionResponseStatus.PENDING_ACTIVATION) {
    return SubscriptionStatus.PENDING_ACTIVATION;
  }
  if (dto.status === SubscriptionResponseStatus.PAUSED) {
    return SubscriptionStatus.PAUSED;
  }
  if (dto.status.toUpperCase() === SubscriptionResponseStatus.Expired.toUpperCase()) {
    return SubscriptionStatus.Expired;
  }
  return SubscriptionStatus.UNKNOWN;
};

const getActionsByStatusAndSource = (status: string, source: SubscriptionSource): string[] => {
  const statusActionsMapping: {
    [status: string]: { [source: string]: string[] };
  } = {
    [SubscriptionStatus.ACTIVE]: {
      [SubscriptionSource.eCom]: [
        "extendBillingDate",
        "applyPromoCode",
        "cancel",
        "revokeEntitlement",
        "pause",
        "upsell",
        "downgrade",
        "switchPlan",
      ],
      [SubscriptionSource.RECURLY]: [
        "extendBillingDate",
        "applyPromoCode",
        "cancel",
        "revokeEntitlement",
        "pause",
        "upsell",
        "downgrade",
        "switchPlan",
      ],
      [SubscriptionSource.Naviga]: ["cancel", "revokeEntitlement"],
    },
    [SubscriptionStatus.FREE_TRIAL]: {
      [SubscriptionSource.eCom]: [
        "extendBillingDate",
        "applyPromoCode",
        "cancel",
        "revokeEntitlement",
        "upsell",
        "downgrade",
        "switchPlan",
      ],
      [SubscriptionSource.RECURLY]: [
        "extendBillingDate",
        "applyPromoCode",
        "cancel",
        "revokeEntitlement",
        "upsell",
        "downgrade",
        "switchPlan",
      ],
      [SubscriptionSource.Naviga]: ["cancel", "revokeEntitlement"],
    },
    [SubscriptionStatus.PENDING_CANCEL]: {
      [SubscriptionSource.eCom]: ["revokeEntitlement"],
      [SubscriptionSource.RECURLY]: ["revokeEntitlement"],
      [SubscriptionSource.Naviga]: ["revokeEntitlement"],
    },
    [SubscriptionStatus.CANCELLED]: {
      [SubscriptionSource.eCom]: ["restart"],
      [SubscriptionSource.RECURLY]: ["restart"],
      [SubscriptionSource.Naviga]: [] as string[],
    },
    [SubscriptionStatus.PENDING_PAUSE]: {
      [SubscriptionSource.eCom]: ["cancel", "revokeEntitlement"],
      [SubscriptionSource.RECURLY]: ["cancel", "revokeEntitlement"],
      [SubscriptionSource.Naviga]: ["cancel", "revokeEntitlement"],
    },
    [SubscriptionStatus.PAUSED]: {
      [SubscriptionSource.eCom]: ["resume", "cancel", "revokeEntitlement"],
      [SubscriptionSource.RECURLY]: ["resume", "cancel", "revokeEntitlement"],
      [SubscriptionSource.Naviga]: ["cancel", "revokeEntitlement"],
    },
    [SubscriptionStatus.GRACE_PERIOD]: {
      [SubscriptionSource.eCom]: ["cancel", "revokeEntitlement"],
      [SubscriptionSource.RECURLY]: ["cancel", "revokeEntitlement"],
      [SubscriptionSource.Naviga]: ["cancel", "revokeEntitlement"],
    },
  };

  if (statusActionsMapping[status] && statusActionsMapping[status][source]) {
    return statusActionsMapping[status][source];
  } else {
    return [];
  }
};

export function transformUpsellDowngradeItem(
  dto: SubscriptionUpsellDowngradeListResponse
): SubscriptionUpsellDowngradeListItem {
  return {
    pid: dto.pid,
    planTitle: dto.plan_title,
    basePrice: {
      id: dto.base_price.id,
      name: dto.base_price.name,
    },
    billingPlan: dto.billing_plan,
  };
}
