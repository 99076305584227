import { Input } from "antd";
import React from "react";
import { isEmpty } from "lodash";

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

export const PromotionQuantity = ({ value = "", onChange, onBlur }: IProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let filteredValue = value.replace(/[^0-9]/g, "");
    filteredValue = isEmpty(filteredValue) ? "" : `${Math.min(Number.parseInt(filteredValue), Math.pow(10, 6))}`;
    onChange && onChange(filteredValue);
  };

  return <Input maxLength={7} value={value} onChange={handleChange} onBlur={onBlur} />;
};
